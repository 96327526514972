import React, { useState } from 'react'
import { convertTimezone } from '../../../../helpers/common'
import { motion } from "framer-motion"
import moment from 'moment'
import SpeakerCardMinimalistic from '../SpeakerCardMinimalistic'
import SideViewModal from '../../GeneralComponents/SideViewModal'

const AgendaSessions = ({ selectedTrackData, eventData }) => {
    const [openModal, setOpenModal] = useState(false)
    const [selectedSession, setSelectedSession] = useState(false)
    const trackSessions = [...selectedTrackData[0].track_sessions]
    trackSessions?.sort((a, b) => a?.session_order - b?.session_order);
    const userTimezone = moment.tz.guess();

    const getDate = (date, format) => {
        return moment(convertTimezone(date, userTimezone)).format(format);
    }

    const date = (session) => {
        let start_month = getDate(`${session.start_timestamp}`, 'MMM Do');
        // let end_month = getDate(`${session.end_timestamp}`, 'MMM Do');
        let start_year = getDate(`${session.start_timestamp}`, 'YYYY');
        // let end_year = getDate(`${session.end_timestamp}`, 'YYYY');
        let start_time = getDate(`${session.start_timestamp}`, "h:mm A");
        let end_time = getDate(`${session.end_timestamp}`, "h:mm A");
        let timezone = moment.tz(`${session.end_timestamp}`, userTimezone).format("z");

        return `${start_time} - ${end_time} ${timezone}`
    }

    function convertToHTML(session) {
        return { __html: `${session?.session_description?.replace(/(?:\r\n|\r|\n)/g, '') || ''}` };
    }

    // console.log(eventData, trackSessions, moment(convertTimezone(trackSessions[0].start_timestamp, timezone)).format("h:mm"))
    return (
        <>
            {!_.isEmpty(trackSessions) ? (
                <>
                    {trackSessions.map(session => (
                        <div className='d-flex flex-lg-row flex-column mb-3' key={`${session.session_id}`}>
                            <div className='mb-2 me-1' style={{ width: "210px", color: "#525252", fontWeight: "600", fontSize: "15px" }}>
                                {getDate(session.start_timestamp, "h:mm A")} - {getDate(session.end_timestamp, "h:mm A")}
                            </div>
                            <motion.button
                                className='session-details py-2 px-3 w-100 text-start bg-white'
                                style={{ border: "0.8px solid #B4B4B4", borderRadius: "6px" }}
                                whileHover={{ scale: 1.03 }}
                                onClick={() => {
                                    if (session.session_description || session.session_speakers.length > 0) {
                                        setOpenModal(prevState => !prevState)
                                        setSelectedSession(session)
                                    }
                                }}
                            >
                                <div className='d-flex session-title-container d-flex justify-content-between'>
                                    <p className='session-title mb-1'>{session.session_title}</p>
                                    {/*<div className='ms-2 mt-1'><i className="fa-regular fa-calendar"></i></div>*/}
                                </div>
                                {session.session_description && <p className='session-description mb-0' dangerouslySetInnerHTML={{ __html: session.session_description }} />}
                                {session.session_speakers.length > 0 && <div className='session-speakers-container mt-2'>
                                    {session.session_speakers.map((speaker) => (
                                        <div className='py-1 px-2 speaker-agenda-container d-inline-block rounded-pill'>
                                            <div className='d-flex h-100 align-items-center'>
                                                <img src={speaker.image_url} className='speaker-img-agenda me-2 rounded-circle' alt='Speaker image' />
                                                <span style={{ fontSize: "14px" }}>{speaker.name}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                            </motion.button>
                            <style jsx>{`
                                .session-title {
                                    font-size: 1.25rem;
                                    font-weight: 700;
                                }
                                .session-details {
                                    border: 0.8px solid #B4B4B4;
                                    border-radius: 6px;
                                }
                                .session-description {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; /* number of lines to show */
                                    -webkit-box-orient: vertical;
                                }
                                .speaker-img-agenda {
                                    width: 22px;
                                }
                                .speaker-agenda-container {
                                    border: 0.6px solid #B4B4B4;
                                }

                                @media (min-width: 992px) and (max-width: 1368px) {
                                    .session-title {
                                        font-size: 1.125rem;
                                    }
                                }
                            `}</style>
                        </div>
                    ))}
                </>
            ) : (
                <div>Sessions for this track are yet to be updated</div>
            )}
            <SideViewModal isOpen={openModal} setIsOpen={setOpenModal} background="#F5F5F5">
                {selectedSession && <div className='p-4'>
                    <div className='text-end mb-3'>
                        <button onClick={() => setOpenModal(false)} className='border-0' style={{
                            background: "#F5F5F5",
                            color: "#525252",
                        }}>
                            <i className='fa-solid fa-xmark' style={{ fontSize: "18px" }}></i>
                        </button>
                    </div>
                    <h2 className='mb-3'>{selectedSession.session_title}</h2>
                    <p style={{ color: "#525252", fontWeight: "600", fontSize: "18px" }} className='mb-3'>{date(selectedSession)}</p>
                    {selectedSession?.session_location && <div className='mb-3 d-flex gap-1 location-text'>
                    <img src="/img/prolocation.svg" alt="" />
                    <p>{selectedSession?.session_location}</p>    
                    </div>}
                    <p className="mb-3" dangerouslySetInnerHTML={convertToHTML(selectedSession)} />
                    {(selectedSession.session_speakers && selectedSession.session_speakers.length > 0) && <h2 style={{ fontWeight: "700", fontSize: "1.125button:focusrem" }} className='mb-3'>SPEAKERS</h2>}
                    <div className='row'>
                        {selectedSession.session_speakers.map(speaker => (
                            <div className='col-12 col-sm-6 col-xxl-6 mb-3'>
                                <SpeakerCardMinimalistic speaker={speaker} layout={"compact"}/>
                            </div>
                        ))}
                    </div>
                    {selectedSession.tags && selectedSession.tags.length > 0 && (
						<div className="speaker-tags">
							<p className="my-3 tag-header">Tags</p>
							<div className="d-flex flex-wrap my-3">
								{selectedSession.tags.map((tag) => (
									<div key={tag.id} className="me-2 mb-2 tag-container">
										<span className="tag-name">#{tag.name}</span>
									</div>
								))}
							</div>
						</div>
					)}
                </div>}
                <style jsx>{`
                .location-text{
                    font-family: "Manrope";
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20.49px;
                    text-align: center;
                }
                .tag-header{
                  font-family: "Prompt";
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #141B25;
                }
                .tag-container{
                  background-color: #FFFFFF;
                  height: 28px;
                  padding: 0px 12px 0px 12px;
                  border-radius: 4px;
                }
                .tag-name{
                  font-family: "Hind";
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  color: #212529;
                }    

                                
                `}</style>
            </SideViewModal>
        </>
    )
}

export default AgendaSessions
