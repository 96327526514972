import React, { useState, useEffect, useRef } from "react";
import Router, { useRouter } from "next/router";
import eventContext from "../../../contexts/EventContext";
import Loading from "../../../components/Loading";
import NavbarPro from "../../../components/NavigationBar/NavbarPro";
import Link from "next/link";
import Head from "next/head";
import { getTicketData } from "../../../crud/dedicatedEvent-crud";
import { pricingSampleData } from "../../../data/sampleEvent";
import _ from "lodash";
import moment from "moment";
import Image from "next/image";
import { useMediaQuery } from "react-responsive";
import AgendaMinimalistic from "./Agenda/AgendaMinimalistic";
import GetCurrency from "../../../helpers/services";
import ReadMore from "../../../components/ReadMoreText";
import dynamic from "next/dynamic";
import { Tab, Tabs } from "./EventDetails/EventNavTabs";
import { CurrencyWithAmount, errorToaster } from "../../../helpers/utils";
import { SOLD_OUT } from "../../UseCases/Constants";
import { EXPIRED } from "../../UseCases/Constants";
import { BUY_NOW } from "../../UseCases/Constants";
import { REGISTER } from "../../UseCases/Constants";
import { useSectionRefs } from "../../../config/customHooks";
import { getEventTranslationConfig, getTranslation, translateTextIfNeeded } from "../../../helpers/common";



const EventPricingMinimalistic = dynamic(
	() => import("./EventPricing/EventPricingMinimalistic"),
);
const EventSpeakersMinimalistic = dynamic(
	() => import("./EventSpeakersMinimalistic"),
);
const EventWorkshopsMinimalistc = dynamic(
	() => import("./EventWorkshop/EventWorkshopsMinimalistc"),
);
const EventAboutMinimalistic = dynamic(
	() => import("./EventAboutMinimalistic"),
);
const EventSponsorsMinimalistic = dynamic(
	() => import("./EventSponsorsMinimalistic"),
);
const EventPartnersMinimalistic = dynamic(
	() => import("./EventPartnersMinimalistic"),
);
const EventExhibitorsMinimalistic = dynamic(
	() => import("./EventExhibitorsMinimalistic"),
);
const EventDetailsMinimalistic = dynamic(
	() => import("./EventDetails/EventDetailsMinimalistic"),
);
const EventOrganiserBox = dynamic(
	() => import("./EventDetails/EventOrganiserBox"),
);



const EventPageMinimalistic = ({
	isReady,
	eventUrl,
	query,
	eventData,
	samplePage = false,
	ticketsData,
	groupDiscounts
}) => {
	// const [eventData, setEventData] = useState(eventData)
	const [currentUrl, setCurrentUrl] = useState("");
	const [CurrSection, setCurrSection] = useState("about");
	const [ticketData, setTicketData] = useState(ticketsData);
	const [activeIndex, setActiveIndex] = useState(0);
	const [ticketStatus, setTicktStatus] = useState("")
	const [isLoading, setIsLoading] = useState(true);
	const [couponCodePage, setCouponCodePage] = useState("");
	const router = useRouter();
	let newQuery = { ...query };
	delete newQuery.eventUrl;
	const faviconIconUrl =
		eventData.fav_icon_urls && eventData.fav_icon_urls["favicon.ico"];

	const handleMediaQueryChange = (matches) => {
		smallerScreen = matches;
		// matches will be true or false based on the value for the media query
	};
	let smallerScreen = useMediaQuery(
		{ maxWidth: 991 },
		undefined,
		handleMediaQueryChange,
	);

	// section refs
	const { sections } = useSectionRefs();

	const getEventPropertiesIfMatch = (eventUrl) => {
		const eventSpecificProperties = {
			"international-restoration-week-en": {
				backgroundColor: "#DED6C7",
				color: "#ffffff",
				buttonBgColor: "#015740",
				textColor: '#383B97'
			},
			"international-restoration-week-ar": {
				backgroundColor: "#DED6C7",
				color: "#ffffff",
				buttonBgColor: "#015740",
				textColor: '#383B97'
			},
		};

		// Check if the eventUrl matches return the properties
		if (eventSpecificProperties[eventUrl]) {
			return {
				match: true,
				properties: eventSpecificProperties[eventUrl]
			};
		}

		// If it doesn't match, return false and no properties
		return { match: false };
	}
	const matchedEventProperties = getEventPropertiesIfMatch(eventUrl);

	const matchedEventTranslationConfig = getEventTranslationConfig(eventUrl);

	useEffect(() => {
		// Scroll to view section
		const sectionId = window.location.hash.replace('#', '');
		const scrollToSectionWithDelay = () => {
			const element = sections[sectionId]?.current;
			if (element) {
				const offsetTop = element.offsetTop;
				const scrollToPosition = offsetTop - 30;
				window.scrollTo({
					top: scrollToPosition,
					behavior: "smooth",
				});
			}
		};

		if (sectionId && sections[sectionId]) {
			setTimeout(scrollToSectionWithDelay, 1000); // Wait 1 second for the page to finish rendering
		}
	}, []);

	const availableTabs = {
		about: eventData.description,
		speakers: eventData?.has_speakers,
		workshops: eventData?.has_workshops,
		sponsors: eventData?.has_sponsors,
		partners: eventData?.has_partners,
		exhibitors: eventData?.has_exhibitors,
		agenda: eventData?.has_agenda,
		venue:
			(!eventData.is_virtual && eventData.venue) ||
			eventData.event_url === "wencarnival",
	};

	const hideHighlight =
		eventData?.expected_speakers === 0 && eventData?.expected_attendees === 0;

	let mybutton = null;

	function topFunction() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	// Scroll to view section
	const scrollToSection = (id) => {
		setActiveIndex(3);
		const element = sections[id].current;

		element.scrollIntoView();
	};

	useEffect(() => {
		if (samplePage) {
			setTicketData(pricingSampleData.uncategorized);
		} else {
			//getTicketDataFunction();
		}
	}, [eventUrl]);

	const getTicketDataFunction = async () => {
		setIsLoading(true)
		try {
			const res = await getTicketData(eventUrl);
			// const res = { data: { ...ticketData } };
			let uncategorized = res.data.uncategorized;
			let allTickets = [...uncategorized];
			res.data.categorized = res.data.categorized.sort(
				(a, b) => a.category_order - b.category_order,
			);
			res.data.categorized.forEach((category) => {
				category.tickets = category.tickets.map((ticket) => {
					ticket.category = {
						category_name: category.category_name,
						category_description: category.category_description,
						category_id: category.category_id,
						category_order: category.category_order,
						is_expanded: category.is_expanded,
					};
					return ticket;
				});
				allTickets = [...allTickets, ...category.tickets];
			});
			let ticketDetails = allTickets;
			let orderedTicket = ticketDetails.sort((a, b) => {
				// Sort the forms by from orderzz
				return a.ticket_order - b.ticket_order;
			});
			let newTickets = [];
			orderedTicket.map((ticket) => {
				if (!ticket.hidden_ticket) newTickets.push(ticket);
				else return;
			}); // temporary
			setTicketData(newTickets);
			setIsLoading(false)
		} catch (err) {
			console.log(err);
			setIsLoading(false)
			errorToaster(
				"Something went wrong while trying to get ticket, please try again later",
			);
		}
	};


	const isTicketSoldOut = () => {
		var todaysTime = moment().valueOf();
		let event_end_timestamp = `${eventData.end_date} ${eventData.end_time}`;
		let eventEndTime = moment.utc(event_end_timestamp).local().valueOf();

		if (!_.isEmpty(ticketData)) {
			let soldoutTickets = ticketData.filter(
				(ticket) =>
					(ticket.sold_out === true || ticket.remaining_count <= 0) &&
					!ticket.waitlist_enabled
			);
			if (soldoutTickets.length === ticketData.length) {
				setTicktStatus(SOLD_OUT);
				return;
			}
		}

		if (eventData.is_free) {
			setTicktStatus(REGISTER);
		} else if (todaysTime > eventEndTime) {
			setTicktStatus(EXPIRED);
		} else {
			setTicktStatus(BUY_NOW);
		}
	};

	const checkNoOfSections = () => {
		let sections = 1;
		if (eventData?.has_speakers) sections += 1;
		if (eventData?.has_workshops) sections += 1;
		if (eventData?.has_sponsors) sections += 1;
		if (eventData?.has_partners) sections += 1;
		if (eventData?.has_exhibitors) sections += 1;
		if (eventData?.has_agenda) sections += 1;
		if (eventData?.description) sections += 1;

		if (sections >= 3) return true;
		return false;
	};

	useEffect(() => {
		//console.log(CurrSection);
	}, [CurrSection]);


	useEffect(() => {
		isTicketSoldOut();
	}, [ticketData, eventData]);

	return (
		<>
			<eventContext.Provider value={{ eventData }}>
				{Object.keys(eventData).length !== 0 ? (
					<>
						{faviconIconUrl ? (
							<Head>
								<link rel="icon" href={faviconIconUrl} />
							</Head>
						) : (
							<Head>
								<link rel="icon" href="./favicon/globe.ico" />
							</Head>
						)}

						<div style={{ backgroundColor: matchedEventProperties?.match ? matchedEventProperties?.properties?.backgroundColor : "#" }}>
							<NavbarPro mobileNavbar={false} color="#000" eventPage={true} />
						</div>
						<div className="container-color pt-0 py-4 px-3 px-sm-4 ">
							{/* <div id="scrollDiv" style={{ display: "none", background: "#FFFFFF", borderRadius: "50%", boxShadow: "0px 0px 7px rgb(77 77 77)" }} onClick={topFunction} className='icon-scroll'>
                                <img src='/img/scroll-top.svg' />
                            </div> */}
							<div className="row">
								<div className="col-12 col-lg-9 col-xxl-9">
									<div className="event-details-bg-container px-3 mt-2 pt-0 px-sm-4  p-md-0 p-lg-4 pt-lg-0">
										<div className="position-relative w-100 event-page-poster-container py-1 px-1 p-sm-2 mb-3" style={{ backgroundColor: "" }} >
											{/* <Image src={eventData.event_poster_url}
                                                width={0}
                                                height={0}
                                                sizes="100vw"
                                                style={{ width: '100%', height: 'auto', borderRadius: "20px" }}
                                                alt={`${eventData.name} poster image`}
                                            /> */}
											<img
												src={eventData.event_poster_url}
												style={{
													width: "100%",
													height: "auto",
													borderRadius: "15px",
												}}
												alt={`${eventData.name} poster image`}
											/>
										</div>
										{smallerScreen && (
											<EventDetailsMinimalistic
												isMobile={true}
												isTicketSoldOut={ticketStatus}
												ticketData={ticketData}
												scrollToSection={scrollToSection}
												hasCoupons={eventData.has_coupons}
												newQuery={newQuery}
												couponCode={couponCodePage}
												matchedEventProperties={matchedEventProperties}
												matchedEventTranslationConfig={matchedEventTranslationConfig}

											/>
										)}
										{checkNoOfSections() && eventData && eventUrl !== "dubai-fintech-summit-2025" && eventUrl !== "dubai-ai-festival" && (
											<div className="mt-2 text-center">
												<Tabs>
													{eventData?.description && (
														<Tab
															title={matchedEventTranslationConfig?.match ? getTranslation('About', matchedEventTranslationConfig?.translateTo) : "About"}
															onClick={() => scrollToSection("about")}
														/>
													)}
													{(ticketsData?.length > 0 || eventData?.has_access_codes === true) &&
														<Tab
															title={matchedEventTranslationConfig?.match ?  getTranslation('Tickets', matchedEventTranslationConfig?.translateTo) : "Tickets"}
															onClick={() => scrollToSection("tickets")}
														/>
													}
													{eventData?.has_speakers && (
														<Tab
															title={
																eventData?.speaker_section_title
																	? eventData?.speaker_section_title
																	: "Speakers"
															}
															onClick={() => scrollToSection("speakers")}
														/>
													)}
													{eventData?.has_workshops && (
														<Tab
															title={
																eventData?.workshop_section_title
																	? eventData?.workshop_section_title
																	: "Workshop"
															}
															onClick={() => scrollToSection("workshops")}
														/>
													)}
													{eventData?.has_agenda && (
														<Tab
															title={"Schedule"}
															onClick={() => scrollToSection("agenda")}
														/>
													)}
													{eventData?.has_sponsors && (
														<Tab
															title={
																eventData?.sponsor_section_title
																	? eventData?.sponsor_section_title
																	: "Sponsor"
															}
															onClick={() => scrollToSection("sponsors")}
														/>
													)}
													{eventData?.has_exhibitors && (
														<Tab
															title={
																eventData?.exhibitor_section_title
																	? eventData?.exhibitor_section_title
																	: "exhibitor"
															}
															onClick={() => scrollToSection("exhibitors")}
														/>
													)}
													{eventData?.has_partners && (
														<Tab
															title={
																eventData?.partner_section_title
																	? eventData?.partner_section_title
																	: "partner"
															}
															onClick={() => scrollToSection("partners")}
														/>
													)}
												</Tabs>
											</div>
										)}
									</div>
									<div className="px-0 ps-lg-4">
										{eventData?.description && eventUrl !== "dubai-fintech-summit-2025" && eventUrl !== "dubai-ai-festival" && (
											<div ref={sections.about}
												onClick={(e) => {
													e.preventDefault();
												}}
												className="custom-link"
											>
												<EventAboutMinimalistic
													setCurrSection={setCurrSection}
													matchedEventProperties={matchedEventProperties}
													matchedEventTranslationConfig={matchedEventTranslationConfig}
												/>
											</div>
										)
										}

										<div ref={sections.tickets} onClick={(e) => {
											e.preventDefault();

										}}
											className="custom-link">
											<EventPricingMinimalistic
												eventUrl={eventUrl}
												setCurrSection={setCurrSection}
												newQuery={newQuery}
												samplePage={samplePage}
												hasCoupons={eventData.has_coupons}
												hasAccessCodes={eventData.has_access_codes}
												ticketData={ticketData}
												eventData={eventData}
												getTicketDataFunction={getTicketDataFunction}
												setTicketData={setTicketData}
												groupDiscounts={groupDiscounts}
												setCouponCodePage={setCouponCodePage}
												// isLoading={isLoading}
												matchedEventProperties={matchedEventProperties}
												matchedEventTranslationConfig={matchedEventTranslationConfig}
											/>
										</div>

										{
											eventData?.description && (eventUrl === "dubai-fintech-summit-2025" || eventUrl === "dubai-ai-festival") && (
												<div ref={sections.about}
													onClick={(e) => {
														e.preventDefault();
													}}
													className="custom-link"
												>
													<EventAboutMinimalistic
														setCurrSection={setCurrSection}
													/>
												</div>
											)
										}

										{
											(eventData?.speaker_section_description || eventData?.has_speakers === true) && (
												<div ref={sections.speakers} onClick={(e) => {
													e.preventDefault();
												}}
													className="custom-link">
													<EventSpeakersMinimalistic
														eventUrl={eventUrl}
														setCurrSection={setCurrSection}
														eventData={eventData}
														samplePage={samplePage}
														matchedEventTranslationConfig={matchedEventTranslationConfig}
													/>
												</div>
											)
										}

										{
											eventData?.has_workshops === true && (
												<div ref={sections.workshops} onClick={(e) => {
													e.preventDefault();
												}}
													className="custom-link">
													<EventWorkshopsMinimalistc
														eventUrl={eventUrl}
														setCurrSection={setCurrSection}
														samplePage={samplePage}
														matchedEventTranslationConfig={matchedEventTranslationConfig}
													/>
												</div>
											)
										}

										{
											eventData?.has_agenda === true && (
												<div ref={sections.agenda} onClick={(e) => {
													e.preventDefault();
												}}
													className="custom-link">
													<AgendaMinimalistic
														eventUrl={eventUrl}
														setCurrSection={setCurrSection}
														eventData={eventData}
														samplePage={samplePage}
													/>
												</div>
											)
										}
										{
											eventData?.has_sponsors === true && (
												<div ref={sections.sponsors} onClick={(e) => {
													e.preventDefault();
												}}
													className="custom-link">
													<EventSponsorsMinimalistic
														eventUrl={eventUrl}
														setCurrSection={setCurrSection}
														eventData={eventData}
														samplePage={samplePage}
														matchedEventTranslationConfig={matchedEventTranslationConfig}
														/>
												</div>
											)
										}
										{
											eventData?.has_partners === true && (
												<div ref={sections.partners} onClick={(e) => {
													e.preventDefault();
												}}
													className="custom-link"
												>
													<EventPartnersMinimalistic
														eventUrl={eventUrl}
														setCurrSection={setCurrSection}
														eventData={eventData}
														samplePage={samplePage}
														matchedEventTranslationConfig={matchedEventTranslationConfig}
													/>
												</div>
											)
										}
										{
											eventData?.has_exhibitors === true && (
												<div ref={sections.exhibitors} onClick={(e) => {
													e.preventDefault();
												}}
													className="custom-link"
												>
													<EventExhibitorsMinimalistic
														eventUrl={eventUrl}
														setCurrSection={setCurrSection}
														eventData={eventData}
														samplePage={samplePage}
														matchedEventTranslationConfig={matchedEventTranslationConfig}
													/>
												</div>
											)
										}
									</div>
								</div>
								<div className="col-12 col-lg-3 col-xxl-3 flex-column justify-content-between d-flex">
									<div className="h-100">
										{!smallerScreen && (
											<EventDetailsMinimalistic
												isMobile={false}
												isTicketSoldOut={ticketStatus}
												ticketData={ticketData}
												scrollToSection={scrollToSection}
												hasCoupons={eventData.has_coupons}
												newQuery={newQuery}
												couponCode={couponCodePage}
												matchedEventProperties={matchedEventProperties}
												matchedEventTranslationConfig={matchedEventTranslationConfig}
											/>
										)}
									</div>
									{smallerScreen && (
										<div className="m-2">
											<EventOrganiserBox  matchedEventProperties={matchedEventProperties} eventData={eventData} matchedEventTranslationConfig={matchedEventTranslationConfig} />
										</div >
									)}
								</div>
								{/* FOOTER 
                <div className="px-3">
                  <div className="mt-5">
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-start ms-3 footer-container-min pt-3 pb-0">
                      <p className="social-link mt">
                        © KonfHub | 2020 - 2023. All Rights Reserved
                      </p>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/konfhub/"
                          className="social-link me-2  ms-0"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Connect on LinkedIn"
                        >
                          <i className="fab fa-linkedin"></i>
                        </a>
                        <a
                          href="https://www.facebook.com/konfhub/"
                          className="social-link me-2"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Connect on Facebook"
                        >
                          <i className="fab fa-facebook-square"></i>
                        </a>
                        <a
                          href="https://twitter.com/konfhub"
                          className="social-link me-2"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Connect on Twitter"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a
                          href="https://konfhub.medium.com"
                          className="social-link me-2"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Connect on Medium"
                        >
                          <i className="fab fa-medium"></i>
                        </a>
                        <a
                          href="https://konf.me/discord"
                          className="social-link me-2"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Connect on Discord"
                        >
                          <i className="fab fa-discord"></i>
                        </a>
                        <a
                          href="https://www.youtube.com/playlist?list=PL80YlDTccZmIeQChHjf_2lR_UHeowZXaL"
                          className="social-link me-2"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Connect on Youtube"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
							</div>
						</div>
					</>
				) : (
					<div className=" vh-100 d-flex justify-content-center align-items-center bg-light align-self-center event-header-container event-header-container-image px-0">
						<Loading color="#141B25" />
					</div>
				)}
			</eventContext.Provider>
			<style jsx global>{`
		.custom-link{
			color: inherit;
			color: ${matchedEventProperties?.match ? matchedEventProperties?.properties?.textColor : "inherit"} ;
			cursor: default;
			text-decoration: none;
		}	
		.custom-link:hover{
			color: ${matchedEventProperties?.match ? matchedEventProperties?.properties?.textColor : "inherit"} ;
			cursor: default;
		}	
        html,
        body {
          background: #fff !important;
		  font-family: "Manrope" !important;
		  
        }
		a {
			text-decoration: none;
		}

        section {
          margin-top: 4rem;
        }

        .min-link-text {
          color: #0d6efd !important;
			cursor:pointer !important;
        }

        .social-link {
          color: #b4b4b4;
          font-size: 0.875rem;
        }

        .footer-container-min a:hover {
          color: #000 !important;
        }

        button:focus {
          box-shadow: none !important;
        }

        .footer-container-min {
          border-top: 0.8px solid #b4b4b4;
        }

        h2 {
          font-size: 1.5625rem;
          font-weight: 800;
          text-align: left;
          margin-bottom: 2rem;
        }

        .event-page-poster-container {
          border-radius: 15px;
          box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.18);
        }

        .event-details-bg-container {
          //background: #F6F6FC;
          border-radius: 15px;
        }

        .minimalistic-btn {
          width: auto;
          padding: 10px 30px;
          font-size: 1rem;
          //background-color: #000;
		  background-color: ${matchedEventProperties?.match ? matchedEventProperties?.properties?.buttonBgColor : "#000"} ;
          cursor: pointer;
          box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
          border: none;
          outline: none;
          border-radius: 5px;
          color: #ffffff;
          font-weight: 500;
          text-decoration: none;
          transition: 0.2s all;
        }

        .minimalistic-btn:hover {
          opacity: 0.8;
        }

        .minimalistic-btn:disabled {
          background: #F0F0F0;
          color: #6C757D;
        }

        .sec-btn {
          background-color: #fff;
          border: 1px solid black;
          color: #000;
        }

        .container-color {
          //background: #ffffff;
		  background: ${matchedEventProperties?.match ? matchedEventProperties?.properties?.backgroundColor : "#ffffff"} ;
          font-family: "Manrope";
          max-width: 1560px;
          margin-left: auto;
          margin-right: auto;
        }
        .sticky {
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 2000;
          transition: box-shadow 0.3s ease-in-out;
        }
        .icon-scroll {
          position: fixed;
          bottom: 4%;
          right: 2%;
          z-index: 10;
        }
        .icon-scroll * {
          width: 40px;
          cursor: pointer;
          height: 40px;
        }
        .content {
          margin-top: 45px;
        }
        .sticky + .content {
          padding-top: 60px;
        }

        @media (min-width: 992px) and (max-width: 1368px) {
          h2 {
            font-size: 1.375rem;
          }
          // section {
          //     margin-top: 3rem;
          // }
        }
        @media only screen and (max-width: 768px) {
          // .icon-scroll{
          //     bottom:11%;
          // }

          .event-details-bg-container {
            width: 100vw;
            margin-left: calc(-50vw + 50%);
          }
        }
		
		@media only screen and (max-width: 576px) {
			.minimalistic-btn {
				width: auto;
				padding: 7px 14px;
				box-shadow: 0 0 0;
				padding: 10px 25px !important;
				border-radius: 2px !important;
				font-size: 14px;
        		font-weight: 600;


			}
		}

      `}</style>
		</>
	);
};

export default EventPageMinimalistic;